<template>
    <div class="component-upload-image">
        <el-upload
            :disabled="disabled"
            :action="uploadImgUrl"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :before-upload="handleBeforeUpload"
            :limit="limit"
            :on-error="handleUploadError"
            :on-exceed="handleExceed"
            name="file"
            :on-remove="handleRemove"
            :show-file-list="isShowFileList"
            :headers="headers"
            :file-list="fileList"
            :on-preview="handlePictureCardPreview"
            :class="{ hide: this.fileList.length >= this.limit }"
        >
            <i class="el-icon-plus"></i>
        </el-upload>

        <!-- 上传提示 -->
        <div class="el-upload__tip" slot="tip" v-if="showTip">
            请上传
            <template v-if="fileSize">
                大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
            </template>
            <template v-if="fileType">
                格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
            </template>
            的文件
        </div>

        <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body>
            <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'

export default {
    props: {
        value: [String, Object, Array],
        // 图片数量限制
        limit: {
            type: Number,
            default: 1,
        },
        // 大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        // fileWidthHeight:{
        //     type: String,
        // },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ['png', 'jpg', 'jpeg'],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            hideUpload: false,
            uploadImgUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            fileList: [],
            isShowFileList: true,
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    // 首先将值转为数组
                    const list = Array.isArray(val) ? val : this.value.split(',')
                    // 然后将数组转为对象数组
                    this.fileList = list.map(item => {
                        if (typeof item === 'string') {
                            item = { name: item, url: item }
                        }
                        return item
                    })
                } else {
                    this.fileList = []
                    return []
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize)
        },
    },
    methods: {
        // 删除图片
        handleRemove(file, fileList) {
            const findex = this.fileList.map(f => f.name).indexOf(file.name)
            this.fileList.splice(findex, 1)
            this.$emit('uploadInput', {
                list: this.listToString(this.fileList),
                limit: this.limit,
            })
        },
        // 上传成功回调
        handleUploadSuccess(res) {
            if (res.code != 0) {
                this.fileList.push({
                    name: res.fileName,
                    url: res.data.url,
                })
                let result = {
                    list: this.listToString(this.fileList),
                    limit: this.limit,
                }
                this.$emit('uploadInput', result)
                //返回 v-model的参数（关键）
                this.$emit('input', this.listToString(this.fileList))
            } else {
                this.isShowFileList = false
                this.fileList = []
                this.$message({
                    type: 'error',
                    message: res.msg,
                })
            }

            this.loading.close()
        },
        // 上传前loading加载
         handleBeforeUpload(file) {
            console.log(file)
            let isImg = false
            if (this.fileType.length) {
                let fileExtension = ''
                if (file.name.lastIndexOf('.') > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
                }
                isImg = this.fileType.some(type => {
                    if (file.type.indexOf(type) > -1) return true
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true
                    return false
                })
            } else {
                isImg = file.type.indexOf('image') > -1
            }

            if (!isImg) {
                this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}图片格式文件!`)
                return false
            }
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`)
                    return false
                }
            }
            if(this.$attrs.fileWidthHeight){
                let fileWidthHeight=this.$attrs.fileWidthHeight
                const isWh = new Promise(function(resolve, reject) {
                    let width =Number(fileWidthHeight.split("*")[0])
                    let height = Number(fileWidthHeight.split("*")[1])
                    let _URL = window.URL || window.webkitURL;
                    let img = new Image();
                    img.onload = function() {
                        console.log(img.width)
                        console.log(img.height)
                    let valid = img.width == width && img.height == height;
                    valid ? resolve() : reject();
                    }
                    img.src = _URL.createObjectURL(file);
                }).then(() => {
                    this.loading = this.$loading({
                        lock: true,
                        text: '上传中',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    return file;
                }, () => {
                    this.$message.error(`上传图片宽高  ${fileWidthHeight} !`)
                    return Promise.reject();
                });

                return isWh
            }
            this.loading = this.$loading({
                lock: true,
                text: '上传中',
                background: 'rgba(0, 0, 0, 0.7)',
            })
        },
        getWidth(file){
            let imgWh=new Promise(function (resolve, reject) {
                    let reader = new FileReader()
                    reader.readAsDataURL(file);
                    reader.onload = function (event) {
                        resolve(reader.result)
                    }
                }).then(result=>{
                    let img = new Image();
                        img.src = result;
                        img.onload = function () { //注意只有onload以后才可以拿到图片信息
                            console.log(img.width)
                            console.log(img.height)
                            return {
                                width: img.width,
                                height: img.height
                            }
                        }
                })
                console.log(imgWh)
                return imgWh
        },
        // 文件个数超出
        handleExceed() {
            this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
        },
        // 上传失败
        handleUploadError() {
            this.$message({
                type: 'error',
                message: '上传失败',
            })
            this.loading.close()
        },
        // 预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = ''
            separator = separator || ','
            for (const i in list) {
                strs += list[i].url + separator
            }
            return strs != '' ? strs.substr(0, strs.length - 1) : ''
        },
    },
}
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
}
</style>
